import http from '../httpConfig';

const appointment = (partnerUuid, agendaUuid) => {
  const partner = partnerUuid;
  const appointment = {
    uuid: agendaUuid,
    id: null,
    details: null,
  };

  const urlGet = `/health_place_partner/agenda/${appointment.uuid}`;
  const urlDelete = `/health_place_partner/${partner}/agenda/${appointment.uuid}`;

  const getAppointmentDetails = () => new Promise((resolve, reject) => {
    http.get(urlGet)
      .then((res) => {
        appointment.details = res.data;
        resolve(appointment.details);
      })
      .catch(reject);
  });

  const cancelAppointment = () => new Promise((resolve, reject) => {
    http.delete(urlDelete)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject();
      });
  });

  const getAppointmentData = () => appointment;

  return {
    getAppointmentDetails,
    cancelAppointment,
    getAppointmentData,
  };
};

export default appointment;
