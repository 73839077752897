<template>
  <article class="appointment-scheduling">
    <scheduling-header />

    <transition
      name="fade"
      mode="out-in"
    >
      <section
        v-if="!ui.isLoading"
        :class="{
          'appointment-cancellation--top': !ui.notFound
            && appointment.details.isOpen
            && !ui.hasCancelled
        }"
        class="appointment-cancellation appointment-summary"
      >
        <template v-if="!ui.hasCancelled">
          <!-- Appointment not found -->
          <div
            v-if="ui.notFound"
            class="appointment-cancellation__not-found"
          >
            <h3 class="appointment-summary__msg-title">
              Seu agendamento não foi encontrado.
            </h3>

            <p class="appointment-cancellation__text">
              Por favor, entre em contato com o administrador.
            </p>
          </div>
          <!-- /Appointment not found -->

          <!-- Appointment found and open -->
          <div
            v-if="!ui.notFound && appointment.details.isOpen"
            class="appointment-cancellation__details"
          >
            <h3 class="appointment-summary__msg-title">
              Olá, {{ appointment.details.patient }}
            </h3>

            <p class="appointment-cancellation__text">
              Veja abaixo os detalhes da sua consulta
            </p>

            <div class="appointment-summary__box">
              <div class="appointment-summary__list">
                <div class="appointment-summary__list-item">
                  <h4 class="appointment-summary__list-title">
                    Médico
                  </h4>

                  <p class="appointment-summary__list-text">
                    Dr{{ appointment.details.doctor.gender === 'F' ? 'a' : '' }}.
                    {{ appointment.details.doctor.name }}
                  </p>
                </div>

                <div class="appointment-summary__list-item">
                  <h4 class="appointment-summary__list-title">
                    Especialidade
                  </h4>

                  <p class="appointment-summary__list-text">
                    {{ appointment.details.medicalSpecialty | toSpecialtyName }}
                  </p>
                </div>

                <div class="appointment-summary__list-item">
                  <h4 class="appointment-summary__list-title">
                    Data e horário
                  </h4>

                  <p class="appointment-summary__list-text">
                    {{ appointment.details.appointmentStart }}
                  </p>
                </div>

                <div
                  v-if="appointment.details.isPresential"
                  class="appointment-summary__list-item"
                >
                  <h4 class="appointment-summary__list-title">
                    Endereço
                  </h4>

                  <p class="appointment-summary__list-text">
                    {{ appointment.details.address }}
                  </p>
                </div>

                <div
                  v-if="!appointment.details.isPrepaid"
                  class="appointment-summary__list-item"
                >
                  <h4 class="appointment-summary__list-title">
                    Pagamento
                  </h4>

                  <p class="appointment-summary__list-text">
                    {{ appointment.details.installments }}x de
                    {{ appointment.details.price | toCurrency }}
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="appointment.details.isPaid"
              class="appointment-summary__call-to-action"
            >
              <ui-button
                v-if="appointment.details.canCancel
                  || isAppointmentCancellable(
                    appointment.details.status,
                    appointment.details.appointmentStartISO,
                  )"
                color="cancel"
                label="Quero cancelar essa consulta"
                @click="confirmCancellation"
              />

              <p
                v-if="!appointment.details.canCancel
                  && appointment.details.isOpen
                  && !isAppointmentCancellable(
                    appointment.details.status,
                    appointment.details.appointmentStartISO,
                  )"
                class="text-center text-gray-400 text-sm"
              >
                Esse agendamento só pode ser cancelado com mais de 24h de antecedência.
              </p>
            </div>
          </div>
          <!-- Appointment found and open -->

          <!-- Appointment found and cancelled -->
          <div
            v-if="!ui.notFound && appointment.details.isCancelled"
            class="appointment-cancellation__details"
          >
            <h3 class="appointment-summary__msg-title">
              Esta consulta foi cancelada.
            </h3>

            <p class="appointment-cancellation__text">
              Para mais informações entre em contato com seu médico.
            </p>
          </div>
          <!-- /Appointment found and cancelled -->

          <!-- Appointment found and closed -->
          <div
            v-if="!ui.notFound && appointment.details.isClosed"
            class="appointment-cancellation__details"
          >
            <h3 class="appointment-summary__msg-title">
              Esta consulta já foi encerrada.
            </h3>

            <p class="appointment-cancellation__text">
              Para mais informações entre em contato com seu médico.
            </p>
          </div>
          <!-- /Appointment found and closed -->
        </template>

        <template v-if="ui.hasCancelled">
          <!-- Appointment cancellation message -->
          <div class="appointment-cancellation__details">
            <h3 class="appointment-summary__msg-title">
              Consulta cancelada com sucesso.
            </h3>

            <p class="appointment-cancellation__text">
              Para mais informações entre em contato com seu médico.
            </p>
          </div>
          <!-- /Appointment cancellation message -->
        </template>
      </section>
    </transition>

    <the-footer
      :class="{
        'landing-page__footer--invisible': (partner.theme === 'sqh' || partner.theme === 'sqh2')
      }"
      class="landing-page__footer--inner"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <loader v-if="ui.isLoading" />
    </transition>
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, parseISO, differenceInHours } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import appointment from '@/api/appointmentDetails/appointment';
import handleError from '@/mixins/handleError';
import validateCancelAppointment from '@/mixins/validateCancelAppointment';
import toSpecialtyName from '@/filters/specialtyName';
import toCurrency from '@/filters/convertToCurrency';
import Loader from '@/components/ui/Loader.vue';
import UiButton from '@/components/ui/Button.vue';
import TheFooter from '@/components/layout/Footer.vue';
import SchedulingHeader from '@/components/appointmentScheduling/Header.vue';

export default {
  name: 'AppointmentDetails',

  mixins: [handleError, validateCancelAppointment],

  filters: {
    toSpecialtyName,
    toCurrency,
  },

  components: {
    SchedulingHeader,
    TheFooter,
    Loader,
    UiButton,
  },

  data() {
    return {
      appointment: {
        interface: null,
        details: {
          isOpen: false,
          isCancelled: false,
          isClosed: false,
          isPaid: true,
          isPrepaid: false,
          canCancel: false,
          doctor: {
            gender: null,
            name: null,
          },
          patient: null,
          price: null,
          installments: null,
          medicalSpecialty: null,
          appointmentStart: null,
          isPresential: false,
          address: null,
        },
      },
      ui: {
        isLoading: false,
        notFound: false,
        hasCancelled: false,
      },
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),

    agendaId() {
      return this.$route.params.agendaId || null;
    },
  },

  created() {
    this.appointment.interface = appointment(this.partner.uuid, this.agendaId);

    this.ui.isLoading = true;
    this.toggleProgressBar();

    this.appointment.interface
      .getAppointmentDetails()
      .then((data) => {
        this.appointment.details.doctor.gender = data.doctor.user.gender;
        this.appointment.details.doctor.name = data.doctor['display_name'];
        this.appointment.details.patient = data.user && 'name' in data.user
          ? data.user.name
          : '';
        this.appointment.details.price = data.value;
        this.appointment.details.installments = data['payment_installment'];
        this.appointment.details.medicalSpecialty = data['medical_specialty'].name;
        this.appointment.details.appointmentStart = format(
          parseISO(data['appointment_start']),
          'd \'de\' MMMM \'de\' yyyy \'às\' HH\':\'mm',
          { locale: ptBr },
        );
        this.appointment.details.appointmentStartISO = data['appointment_start'];
        this.appointment.details.status = data.status;

        this.appointment.details.isPresential = !(/telemedicine/gi.test(data.type)) && !(/telemedicina/gi.test(data.type));

        if (this.appointment.details.isPresential) {
          const { address } = data['doctor_office'];
          this.appointment.details.address = `${
            address.street
          }, ${
            address.number
          }${
            address.complement ? `, ${address.complement}` : ''
          }, ${
            address.district
          } - ${
            address.city.name
          }/${
            address.uf.name
          }`;
        }

        this.appointment.details.isPrepaid = /healthplace pr(é|e) pago/gi.test(data['agenda_payment']['payment_method']);

        const setCancelStatus = (start) => {
          const when = parseISO(start);
          const period = differenceInHours(when, new Date());

          return period > 24;
        };

        switch (true) {
          case /pagamento n(a|ã)o conclu(í|i)do/gi.test(data.status):
            this.appointment.details.isOpen = true;
            this.appointment.details.isClosed = false;
            this.appointment.details.isCancelled = false;
            this.appointment.details.canCancel = false;
            this.appointment.details.isPaid = false;
            break;

          case /aberto/gi.test(data.status):
            this.appointment.details.isOpen = true;
            this.appointment.details.canCancel = setCancelStatus(data['appointment_start']);
            break;

          case /realizado/gi.test(data.status):
            this.appointment.details.isClosed = true;
            break;

          case /cancelado/gi.test(data.status):
            this.appointment.details.isCancelled = true;
            break;

          default:
        }
      })
      .catch(() => {
        this.ui.notFound = true;
      })
      .finally(() => {
        this.ui.isLoading = false;
        this.toggleProgressBar(false);
      });
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),

    confirmCancellation() {
      this.openModalDialog({
        title: 'Confirmação',
        type: 'confirmation',
        text: `Deseja realmente cancelar essa
          consulta? Essa ação não poderá ser desfeita.
        `,
        cancelText: 'Não',
        confirmText: 'Sim',
        fn: () => {
          this.cancelAppointment();
          this.closeModalDialog();
        },
      });
    },

    cancelAppointment() {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.appointment.interface
        .cancelAppointment()
        .then(() => {
          this.ui.hasCancelled = true;
        })
        .catch(this.handleError)
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>
