<template>
  <header class="header">
    <p
      :style="{ 'opacity': partner.hasIframe ? 0 : 1 }"
      class="header__help"
    >
      Para obter ajuda, entre em contato através do

      <a
        class="header__help-link"
        :href="`mailto:${partner.contactEmail}`"
      >
        {{ partner.contactEmail }}
      </a>
    </p>

    <div class="header__logo">
      <a
        v-if="!partner.hasIframe"
        :href="homeLink"
      >
        <img
          :alt="partner.name"
          :src="require(`@/assets/img/logo/${partner.logo.header}`)"
          class="header__img"
        >
      </a>
    </div>

    <div class="header__safe">
      <img
        :style="{ 'opacity': partner.hasIframe ? 0 : 1 }"
        src="@/assets/img/icons/ico-ssl.png"
        alt="Ambiente Seguro"
        class="header__img"
      >
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppointmentSchedulingHeader',

  computed: {
    ...mapGetters('partner', ['partner']),

    homeLink() {
      return `${document.location.protocol}//${document.location.host}`;
    },
  },
};
</script>
