import { isAfter, addMinutes, parseISO } from 'date-fns';
import { APPOINTMENT_CANCELLABLE_OFFSET_MINUTES } from '@/data/constants';

export default {
  methods: {
    isAppointmentCancellable(status = null, date = null) {
      if (status && date && /aberto/gi.test(status)) {
        const now = new Date();
        const start = parseISO(date);
        const startPlus15 = addMinutes(start, APPOINTMENT_CANCELLABLE_OFFSET_MINUTES);

        return isAfter(now, startPlus15);
      }

      return false;
    },
  },
};
